<div *ngIf="loading" class="loading-screen-wrapper">
  <div class="loading-screen-icon">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    Loading...
  </div>
</div>