import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs/Observable';
import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class SocketService {
  private socket: any;
  _socketUrl: string;
  constructor(private configService: ConfigService) {
    //alert("shiv");
  }
  //{transports: ['websocket']}
  socketconnect() {
    this._socketUrl = this.configService.getbaseurl();
    this.socket = io(this._socketUrl);

    this.socket.on('error', console.error.bind(console));
    this.socket.on('message', console.log.bind(console));

    return this.socket;
  }

  joinRoom(data) {
    //console.log('socket.io', data);
    this._socketUrl = this.configService.getbaseurl();
    this.socket = io(this._socketUrl);
    this.socket.emit('join', data);
  }

  leaveRoom(data) {
    this.socket.emit('leave', data);
  }

  sendmassage(data) {
    this.socket.emit('setmassage', data);
  }

  getmassage() {
    const observable = new Observable<{ user: string; message: string }>(
      (observer) => {
        this.socket.on('getmassage', (data) => {
          observer.next(data);
        });
        return () => {
          this.socket.disconnect();
          console.log('disconnect');
        };
      }
    );
    return observable;
  }

  adminmassage() {
    this.socket.emit('arbitratorjoin', { reg: 'arbitrator' });
  }

  sendNotification(data) {
    this.socket.emit('setNotification', data);
  }

  getNotification() {
    const observable = new Observable<{ user: string; message: string }>(
      (observer) => {
        this.socket.on('getNotification', (data) => {
          observer.next(data);
        });
        return () => {
          this.socket.disconnect();
        };
      }
    );
    return observable;
  }

  getMeasurement() {
    const observable = new Observable<{ user: string; message: string }>(
      (observer) => {
        this.socket.on('getMeasurement', (data) => {
          observer.next(data);
        });
        return () => {
          this.socket.disconnect();
        };
      }
    );
    return observable;
  }

  getStatusOCR() {
    const observable = new Observable<{ user: string; message: string }>(
      (observer) => {
        this.socket.on('getStatusOCR', (data) => {
          observer.next(data);
        });
        return () => {
          this.socket.disconnect();
        };
      }
    );
    return observable;
  }

  logoutsocket() {
    this.socket.on('logoutsocket', (data) => {
      console.log('log Out successfully');
    });
  }
}
