import { SocketService } from './socket.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { parse } from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseurl = "";
  reqHeader: any;
  jsonheader: any;
  userdata: any = {};
  userdetails: any = {};
  getLoggedInstt = new Subject();
  public profiledata = new Subject();
  setpermission = new Subject();
  private casetoken = "";
  private loginstt = false;
  public udata = new Subject();
  constructor(private http: HttpClient, private config: ConfigService,
    private router: Router, private _socket: SocketService) {
    this.baseurl = this.config.getbaseurl();
    this.reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    this.jsonheader = new HttpHeaders({ 'Content-Type': 'application/json' });
  }
  getToken() {
    var onbechmarktokenadmin = localStorage.getItem('jwtTokenAdmin');
    if (onbechmarktokenadmin) {
      return onbechmarktokenadmin;
    } else {
      return 'no token';
    }
  }
  logout() {
    this.loginstt = false;
    this.userdata = {};
     this.userdetails = {};
     localStorage.removeItem('jwtTokenAdmin');
     localStorage.removeItem('userInfo');
     this._socket.logoutsocket();
    this.router.navigate(['/']);
  }
  alllogout() {
    this.loginstt = false;
    this.userdata = {};
    this.userdetails = {};
    localStorage.removeItem('jwtTokenAdmin');
    localStorage.removeItem('userInfo');
    this.router.navigate(['/']);
  }
  checkadmin(){
    if (this.userdata && this.userdata.loginType!=1) {
      this.logout();
    }
  }
  setprofiledata(data){
    this.userdata.freelanerId=data;
  }
  logout2() {
    this.loginstt = false;
    this.userdata = {};
    this.userdetails = {};
    localStorage.removeItem('jwtTokenAdmin');
    localStorage.removeItem('userInfo');
     this.router.navigate(['/']);
  }
  login(data): Promise<any> {
    return this.http.post(this.baseurl + 'auth/adminlogin', data, { headers: this.jsonheader }).toPromise();
  }
  loginoutall(data): Promise<any> {
    return this.http.post(this.baseurl + 'auth/logoutalldevice', data, { headers: this.jsonheader }).toPromise();
  }
  getloginstt() {
    this.profiledata.next(this.userdata);
    return this.loginstt; 
  }
  getloginData() {
    return this.userdata;
  }
  setloginData(data) {
    this.loginstt = true;
    this.userdata = data;
  }
  loggedIn() {
    if (this.loginstt) {
      return true;
    }
    if (localStorage.getItem('jwtTokenAdmin')) {
      return true; 
    } else {
      return false;
    }
  }
  loginsuccess(){
    if (localStorage.getItem('jwtTokenAdmin')) {
      var data = JSON.parse(localStorage.getItem('userInfo'));
      //console.log("userInfo",data);
      this.setloginData(data);
      this.profiledata.next(data);
      return true; 
    } else {
      return false;
    }
  }
  commonchecklogin(){
    var data = localStorage.getItem('jwtTokenAdmin');
    if (data != null){
      window.location.href= '/admin';
    }
  }
  usergetdata() {
    var data = localStorage.getItem('userInfo');
    this.setloginData(JSON.parse(data));
    this.profiledata.next(JSON.parse(data));
  }
  setloginapply(data) { 
    this.setpermission.next(data);
  }
  checklogin() {
    var data = localStorage.getItem('jwtTokenAdmin');
    var user = localStorage.getItem('userInfo');
    this.setloginData(JSON.parse(user));
    this.udata.next(JSON.parse(user));
  }
  otpsubmit(data): Promise<any> {
    return this.http.post(this.baseurl + 'auth/otpverify', data, { headers: this.jsonheader }).toPromise();
  }
  forgotSubmit(data): Promise<any> {
    console.log(data);
    return this.http.post(this.baseurl + 'auth/forgotpassword', data, { headers: this.jsonheader }).toPromise();
  }
  resetPassword(data) {
    return this.http.post(this.baseurl + 'auth/resetpassword', data, { headers: this.jsonheader }).toPromise();
  }
  emailverify(data) {
    return this.http.get(this.baseurl + 'auth/emailverify/'+data).toPromise();
  }
  updatetoaken(data) {
    return this.http.get(this.baseurl + 'auth/updatetoaken/'+data).toPromise();
  }
  register(data) {
    return this.http.post(this.baseurl + 'auth/createAccount', data, { headers: this.jsonheader }).toPromise();
  }
  freelancercreate(data) {
    return this.http.post(this.baseurl + 'auth/freelancercreate', data, { headers: this.jsonheader }).toPromise();
  }
  contactus(data) {
    return this.http.post(this.baseurl + 'auth/contactus', data, { headers: this.jsonheader }).toPromise();
  }
  getcity(data): Promise<any> {
    return this.http.get(this.baseurl + 'notoken/city?filter=' + data).toPromise();
  }
  getcaptcha(){
    return this.http.get(this.baseurl + 'auth/getcaptcha').toPromise();
  }
  getSkill(data): Promise<any> {
    return this.http.get(this.baseurl + 'notoken/skill?filter=' + data).toPromise();
  }
  homegetcity(data): Promise<any> {
    return this.http.get(this.baseurl + 'notoken/city?filter=' + data).toPromise();
  }
  homegetSkill(data): Promise<any> {
    return this.http.get(this.baseurl + 'notoken/skill?filter=' + data).toPromise();
  }
  Find(data, tbl): Promise<any> {
    return this.http.get(this.baseurl + 'notoken/' + tbl + '?filter=' + data).toPromise();
  }
  FindbyId(ids, tbl): Promise<any> {
    return this.http.get(this.baseurl + 'notoken/' + tbl + '/' + ids).toPromise();
  }
  create(data, tbl): Promise<any> {
    return this.http.post(this.baseurl + 'notoken/' + tbl, data, { headers: this.jsonheader }).toPromise();
  }
  update(ids, data, tbl): Promise<any> {
    return this.http.put(this.baseurl + 'notoken/' + tbl + '/' + ids, data, { headers: this.jsonheader }).toPromise();
  }
  delete(ids, tbl): Promise<any> {
    return this.http.delete(this.baseurl + 'notoken/' + tbl + '/' + ids).toPromise();
  }
  submitcomment(data, tbl) {
    return this.http.post(this.baseurl + 'diffapi/' + tbl, data, { headers: this.jsonheader }).toPromise();
  }
  getblogInfo(data) {
    return this.http.get(this.baseurl + 'diffapi/getblogInfo/'+data).toPromise();
  }
  blogdetail(data) {
    return this.http.get(this.baseurl + 'diffapi/blogdetail/'+data).toPromise();
  }
}