import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from './canonical.service';


@Injectable({
  providedIn: 'root'
})
export class SEOServiceService {
  constructor(private title: Title, private meta: Meta,private canonical : CanonicalService) {

  }
  updateTitle(title: string) {
    this.title.setTitle(title);
    this.meta.updateTag({ name: 'og:title', content: title });
    this.meta.updateTag({ name: 'og:site_name', content: 'Care4Parents' });
  }
  updateOgUrl(url: string) {
    this.meta.updateTag({ name: 'og:url', content: url });
    this.canonical.setCanonicalURL(url);
    this.canonical.setJsonLd({"shiv":'shiv'});
    //this.meta.updateTag( { rel: 'canonical', href: url } );
  }
  updateDescription(desc: string){
    this.meta.updateTag({ name: 'description', content: desc });
    this.meta.updateTag({ name: 'og:description', content: desc });
    this.meta.updateTag({ name: 'og:type', content: 'Website' });
    this.meta.updateTag({ name: 'og:image', content: 'https://www.care4parents.in/assets/images/logo.png' });
    this.meta.updateTag({ name: 'og:image:width', content: '540' });
    this.meta.updateTag({ name: 'og:image:height', content: '120' });
  }
  updatekeywords(desc: string){
    this.meta.updateTag({ name: 'keywords', content: desc });
  }
  updateseo(keywords, desc) {
    this.meta.updateTag({ name: 'keywords', content: keywords });
    this.meta.updateTag({ name: 'description', content: desc });
  }
  addmetatag(obj){
    this.title.setTitle(obj.title);
    this.meta.updateTag({ name: 'keywords', content: obj.keywords });
    this.meta.updateTag({ name: 'description', content: obj.description });
    this.meta.updateTag({ name: 'og:title', content: obj.title });
    this.meta.updateTag({ name: 'og:site_name', content: 'Care4Parents' });
    this.meta.updateTag({ name: 'og:description', content: obj.description });
    this.meta.updateTag({ name: 'og:type', content: obj.type });
    this.meta.updateTag({ name: 'og:image', content: obj.image });
    this.meta.updateTag({ name: 'og:image:secure_url', content: obj.image });
    this.meta.updateTag({ name: 'og:image:width', content: obj.width });
    this.meta.updateTag({ name: 'og:image:height', content: obj.height });
    this.meta.updateTag({ name: 'og:url', content: obj.url });

    this.canonical.setCanonicalURL(obj.url);
    this.meta.updateTag({ name: 'twitter:card', content: "summary_large_image"});
    this.meta.updateTag({ name: 'twitter:description', content:  obj.title});
    this.meta.updateTag({ name: 'twitter:title', content:obj.title});
    this.meta.updateTag({ name: 'twitter:site', content: "@Care4Parents"});
    this.meta.updateTag({ name: 'twitter:image', content: obj.image});
    this.meta.updateTag({ name: 'twitter:creator', content:"@amysept" });
    this.canonical.setJsonLd({"shiv":'shiv'});
    //<meta name="twitter:card" content="summary_large_image" />
//<meta name="twitter:description" content="What insights can be gleaned from The Upwork 100? Here are three trends that Upwork&#039;s chief economist has talked about in recent interviews." />
//<meta name="twitter:title" content="The Upwork 100: 3 Things You May Have Missed - Upwork Blog" />
// <meta name="twitter:site" content="@Upwork" />
// <meta name="twitter:image" content="https://content-static.upwork.com/blog/uploads/sites/4/2019/12/04072725/upwork-blog-upwork-100-3-things-may-have-missed.jpg" />
// <meta name="twitter:creator" content="@amysept" />



  }
}