import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./auth/authapp.module').then((mod) => mod.AuthappModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/dashboard-admin/admin.module').then(
        (mod) => mod.AdminModule
      ),
  },
  {
    path: 'admin/user',
    loadChildren: () =>
      import('./admin/usermanage/usermanage.module').then(
        (mod) => mod.UsermanageModule
      ),
  },
  {
    path: 'admin/report',
    loadChildren: () =>
      import('./admin/invoice-ordermanage/invoice-ordermanage.module').then(
        (mod) => mod.InvoiceOrdermanageModule
      ),
  },
  {
    path: 'admin/package',
    loadChildren: () =>
      import('./admin/packagemanage/packagemanage.module').then(
        (mod) => mod.PackagemanageModule
      ),
  },
  {
    path: 'admin/master',
    loadChildren: () =>
      import('./admin/master-manage/master-manage.module').then(
        (mod) => mod.MasterManageModule
      ),
  },
  {
    path: 'admin/subadmin',
    loadChildren: () =>
      import('./admin/adminManage/adminManage.module').then(
        (mod) => mod.AdminManageModule
      ),
  },
  {
    path: 'admin/doctorsys',
    loadChildren: () =>
      import('./admin/doctorsystem/doctorsystem.module').then(
        (mod) => mod.DoctorsystemModule
      ),
  },
  {
    path: 'admin/medicinelogs',
    loadChildren: () =>
      import('./admin/medicinelogs/medicinelogs.module').then(
        (mod) => mod.MedicinelogsModule
      ),
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

// @NgModule({
//   imports: [RouterModule.forRoot(routes)],
//   exports: [RouterModule]
// })
// export class AppRoutingModule { }
