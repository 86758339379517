import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private baseurl = "";
  reqHeader: any;
  jsonheader: any;
  skilllist:any=[];
  bannerList:any=[];
  constructor(private http: HttpClient, private config: ConfigService, private router: Router) {
    this.baseurl = this.config.getbaseurl();
    this.reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    this.jsonheader = new HttpHeaders({ 'Content-Type': 'application/json' });
  }
  Find(data, tbl) {
    return this.http.get(this.baseurl + 'notoken/' + tbl + '?filter=' + data);
  }
  FindbyId(ids, tbl) {
    return this.http.get(this.baseurl + 'notoken/' + tbl + '/' + ids);
  }
  setSkill(list){
    this.skilllist = list;
  }
  setBanner(list){
    this.bannerList = list;
  }
  getSkill(){
    return this.skilllist;
  }
  getBanner(){
    return this.bannerList;
  }
}