import { LoaderService } from './loader.service';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable() //{providedIn: 'root'}
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private auth: AuthService, private loaderService: LoaderService) {
    // alert(this.auth.getToken());
  }
  // intercept request and add token
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // modify request
    //console.log('intercept setHeaders', this.auth.getToken());
    request = request.clone({
      setHeaders: {
        Authorization: `${this.auth.getToken()}`,
      },
    });
    //console.log('intercept setHeaders done', request);
    this.showLoader();
    return next.handle(request).pipe(
      tap(
        (event) => {
          //console.log('intercept setHeaders event', event);
          if (event instanceof HttpResponse) {
            this.onEnd();
            //console.log(' all looks good');
          }
        },
        (error) => {
          this.onEnd();
          console.log('error - intercept', error);
          if (error.message === 'Failed token') {
            this.auth.alllogout();
          }
        }
      )
    );
  }
  private onEnd(): void {
    this.hideLoader();
  }
  private showLoader(): void {
    this.loaderService.show();
  }
  private hideLoader(): void {
    this.loaderService.hide();
  }
}

// error:
// auth: false
// message: "Failed token."
