import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  _Host_name = window.location.hostname.toLowerCase();
  _Is_Localhost = this._Host_name == 'localhost';
  // _Is_Care4Parents = this._Host_name == 'admin.care4parents.in';

  
  baseurl = this._Is_Localhost ? 'http://localhost:1338/' :  'https://apis.fuelencer.com/';
  imgurl = this._Is_Localhost ? 'http://localhost:1338' :  'https://apis.fuelencer.com';
  baseurl2 = this._Is_Localhost ? 'http://localhost:4202/' :  'https://apis.fuelencer.com/';
  portal = this._Is_Localhost ? 'http://localhost:4202/' :  'https://fuelencer.com/';
  
  /*
  baseurl = this._Is_Localhost ? 'http://localhost:1338/' : this._Is_Care4Parents ? 'https://apis.care4parents.in/' : 'https://apis.myhealthmatters.in/';
  imgurl = this._Is_Localhost ? 'http://localhost:1338' : this._Is_Care4Parents ? 'https://apis.care4parents.in' : 'https://apis.myhealthmatters.in';
  baseurl2 = this._Is_Localhost ? 'http://localhost:4202/' : this._Is_Care4Parents ? 'https://admin.care4parents.in/' : 'https://admin.myhealthmatters.in/';
  portal = this._Is_Localhost ? 'http://localhost:4202/' : this._Is_Care4Parents ? 'https://portal.care4parents.in/' : 'https://portal.myhealthmatters.in/';
  */
  
  constructor() {}

  getbaseurl() {
    return this.baseurl;
  }
  getbaseurl2() {
    return this.baseurl2;
  }
  getPortal() {
    return this.portal;
  }
  getimgurl() {
    return this.imgurl;
  }
}
