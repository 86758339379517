import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CanonicalService {
  constructor(@Inject(DOCUMENT) private dom,private router: Router) { }

  setCanonicalURL(url?: string) {
    const canURL = url == undefined ? this.dom.URL : url;
    //const canURL = window.location.href;//document.location.href
   
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
    console.log(link);
  }
  setJsonLd( data) {
  var el = this.dom.createElement('script');
  el.type = 'application/ld+json';
  el.text = JSON.stringify({
    "@context": "http://schema.org/",
    "@type": "Organization",
    "name": "Care4Parents",
    "url": "http://www.care4parents.in/",
    "logo": "https://www.care4parents.in/assets/images/logo.png",
    "sameAs" : [
    "https://twitter.com/Care4Parents",
    "https://www.facebook.com/Care4Parents/",
    "https://www.linkedin.com/company/Care4Parents.com?originalSubdomain=in",
    "https://www.instagram.com/Care4Parents/"
    ]
  });
  this.dom.head.appendChild(el);

  var el = this.dom.createElement('script');
  el.type = 'application/ld+json';
  el.text = JSON.stringify({
    "@context": "http://schema.org",
    "@type": "WebSite",
    "name": "Care4Parents",
    "url": "https://www.care4parents.in"
  });
  this.dom.head.appendChild(el);
  ///this.dom.querySelector('head').appendChild(el);

}

}
